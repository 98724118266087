<template>
  <b-sidebar
    id="add-new-zona-sidebar"
    :visible="isAddNewZonaSidebarActive"
    :close-sidebar="closeAddNewZonaSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-zona-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          Zona
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-form-group
          label="Nombre de la zona"
          label-for="nombre"
        >
          <b-form-input
            id="nombre"
            v-model="zona.nombre"
            autofocus
            trim
          />
        </b-form-group>

        <b-form-group
          label="Núcleo al que pertenece"
          label-for="nucleo"
        >

          <v-select
            v-model="selectedZona"
            :options="zonasData"
            label="nombre"
            autofocus
            trim
          /></b-form-group>
        <b-form-checkbox
          v-model="zona.isMantenimiento"
          :unchecked-value="0"
          :value="1"
        >
          Zona con mantenimiento
        </b-form-checkbox>
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="createZona()"
          >
            <b-spinner
              v-if="loading"
              small
            />
            <span v-else>Guardar</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="cancelAction()"
          >
            Cancelar
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BCard,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import zonaStoreModule from '@/views/work/zonas/zonaStoreModule'
import store from '@/store'
import useZonasList from '@/views/work/zonas/useZonasList'

export default {
  components: {
    BSpinner,
    BCard,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormTextarea,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewZonaSidebarActive',
    event: 'update:is-add-new-zona-sidebar-active',
  },
  props: {
    isAddNewZonaSidebarActive: {
      type: Boolean,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: false,
    },
    editItem: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      zonasData: [],
      zona: {
        nombre: '',
        parent_id: null,
      },
      selectedZona: null,
    }
  },
  computed: {
    loading() {
      return this.$store.state['app-zona'].loading
    },
    zonas() {
      return this.$store.state['app-zona'].zonas.filter(
        item => item.parent == null,
      )
    },
  },
  watch: {
    editItem(newVal) {
      if (newVal) {
        this.selectedZona = this.editItem.parent
        this.zona.nombre = newVal.nombre || ''
        this.zona.isMantenimiento = newVal.isMantenimiento
      }
    },
  },
  mounted() {
    this.listZonas().then(zonas => {
      this.zonasData = zonas.filter(item => item.parent == null)
    })
  },
  setup() {
    const ZONAS_APP_STORE_MODULE_NAME = 'app-zona'

    if (!store.hasModule(ZONAS_APP_STORE_MODULE_NAME)) {
      store.registerModule(ZONAS_APP_STORE_MODULE_NAME, zonaStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(ZONAS_APP_STORE_MODULE_NAME)) store.unregisterModule(ZONAS_APP_STORE_MODULE_NAME)
    })

    const { listZonas, refetchData } = useZonasList()

    return {
      refetchData,
      listZonas,
    }
  },
  methods: {
    refreshItem() {
      this.refetchData()
    },
    cancelAction() {
      this.zona.nombre = ''
      this.selectedZona = null
      this.$emit('closeDialog')
    },
    async createZona() {
      const parentSave = this.selectedZona
      const withoutParent = this.zona.nombre
      const zoneObj = {
        nombre: withoutParent,
        parent_id: parentSave ? parentSave.id : undefined,
        id: this.editItem && this.editItem.id ? this.editItem.id : null,
        isMantenimiento: this.zona.isMantenimiento,
      }

      if (this.editItem && this.editItem.id) {
        // Edit item
        await this.$store.dispatch('app-zona/editZona', zoneObj)
      } else {
        // Create
        await this.$store.dispatch('app-zona/addZona', zoneObj)
        this.$emit('addZona', zoneObj)
      }
      this.closeAddNewZonaSidebar()
      this.$emit('refetch-data')
      this.$emit('rowClicked')
      this.cancelAction()
    },
    closeAddNewZonaSidebar() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-departamento-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
